<template>
  <article class="streaming-wrapper">
    <div class="buttons">
      <v-text-field label="some text" v-model="message" outlined dense requried />
      <v-btn @click="send(message)"> SEND</v-btn>
    </div>

    <v-container class="main-container">
      <v-row>
        <v-col cols="12" sm="6" md="8">
          <iframe class="frame" ref="frame" :src="url"></iframe>
        </v-col>
        <v-col cols="6" md="4" class="receive-messages">
          <ul>
            <li v-for="(item, i) in messages" :key="i"> received: {{ item }} </li>
          </ul>
        </v-col>
      </v-row>

    </v-container>
  </article>
</template>

<script>
import loader from "@/config.loader";

export default {
  data(){
    return {
      message: null,
      messages: []
    }
  },
  computed: {
    url() {
      const params = [];

      params.push(`nameProject=${this.projectName}`);
      params.push(`version=: ${this.getAppVersion()}`)

      const query = params.length > 0
        ? '?' + params.join('&')
        : '';

      console.log(`px-url: ${this.baseUrl}`);
      return `${this.baseUrl}/${query}`;
    },
    projectName() {
      return loader.getConfigValue("VUE_PS_PROJECT_NAME");
    },
    baseUrl() {
      return loader.getConfigValue("VUE_PS_IFRAME_URL");
    },
    getAppVersion() {
      return loader.getConfigValue("VUE_APP_VERSION");
    }
  },
  mounted() {
    console.log(`start, version: ${this.getAppVersion()}`);
    window.addEventListener('message', event => {
      if (event.origin !== this.baseUrl) {
        this.messages = [...this.messages, JSON.stringify(event.data)];
        return;
      }

      this.messages = [...this.messages, JSON.stringify(event.data)];
    });

    setTimeout(() => this.$refs.frame.contentWindow.postMessage('Message from outside', '*'), 3000);
  },
  methods: {
    send(message) {
      console.log(message);
      this.$refs.frame.contentWindow.postMessage(message, '*');
    }
  }
}
</script>

<style scoped lang="scss">
.streaming-wrapper {
  display: grid;
  place-content: start;

  height: 100%;

  margin: 1rem;


  position: relative;
}

.frame {
  width: 65%;
  height: 85%;

  position: absolute;
  display: flex;

  top: 60px; bottom: 0; left: 0; right: 0;
}

.buttons {
  display: flex;
  flex-flow: row nowrap;

  justify-content: flex-end;
  width: 40vw;
  //align-items: center;
  align-self: flex-end;

  padding: 0.5rem ;

  & > * {
    margin: 0 1rem;
  }

  & > *:first-child {
    margin-left: 0;
  }

  & > *:last-child {
    margin-right: 0;
  }
}

.main-container {
  min-width: 95vw;
}

.receive-messages {
  text-align: start;
}
</style>
